<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { getAnalysisIconAndColor } from '@/stores/views/Savings'

const emit = defineEmits<{
  (e: 'delete-saving'): void,
  (e: 'close'): void}
>()

const { t } = useI18n()

defineProps<{
  // TODO: Add type for saving
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saving: any,
  value: boolean
}>()

const onClose = () => {
  emit('close')
}

const onDeleteSaving = () => {
  emit('delete-saving')
}
</script>

<template>
  <v-dialog
    max-width="400px"
    :model-value="value"
    @click:outside="onClose"
    @keydown.esc.prevent="onClose"
  >
    <v-card
      data-testid="saving-delete-dialog"
      class="tw-bg-[rgb(var(--v-theme-neutral-lighten3))] tw-border-[1px] tw-border-solid tw-border-[rgb(var(--v-theme-neutral-lighten1))]"
    >
      <v-card-title
        data-testid="saving-delete-dialog-title"
        class="text-h6 px-6 pt-6 pb-0 mb-6"
      >
        {{ t('dialog_heading') }}
      </v-card-title>
      <v-card-text class="px-6 py-0">
        <v-card
          class="tw-border-[1px] tw-border-solid tw-border-[rgb(var(--v-theme-neutral-lighten1))] pa-2"
        >
          <v-icon
            class="mr-2"
            size="14"
            :color="getAnalysisIconAndColor(saving.analysis?.custom.state).cssStyle.color"
          >
            {{ getAnalysisIconAndColor(saving.analysis?.custom.state).icon }}
          </v-icon>
          <span
            data-testid="saving-delete-dialog-state"
            :style="{ color: getAnalysisIconAndColor(saving.analysis?.custom.state).cssStyle.color}"
          >{{ saving.analysis?.custom.state }}</span>
          <p
            data-testid="saving-delete-dialog-text"
            class="ml-5 tw-text-[rgb(var(--v-theme-neutral-darken1))]"
          >
            {{ saving.component.text }}
          </p>
        </v-card>
        <p
          class="mt-4 text-neutral-darken3 text-body-1"
        >
          {{ t('delete_description') }}
        </p>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-btn
          class="flex-grow-1 text-neutral-darken2 tw-border-[1px] tw-border-solid tw-border-[rgb(var(--v-theme-neutral-lighten1))]"
          color="neutral-lighten5"
          data-testid="saving-delete-dialog-keep-button"
          height="40px"
          variant="elevated"
          @click="onClose"
        >
          {{ t('keep_btn_text') }}
        </v-btn>
        <v-btn
          color="error-darken2"
          class="flex-grow-1 text-white saving-delete-dialog-delete-button"
          data-testid="saving-delete-dialog-delete-button"
          height="40px"
          variant="elevated"
          @click="onDeleteSaving"
        >
          {{ t('delete_btn_text') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="sass" scoped>
  .bordered-title
    border: 1px solid rgb(var(--v-theme-neutral-lighten1))
    border-radius: 5px
</style>

<i18n lang="json" locale="de">
  {
    "dialog_heading": "Diese Einsparung löschen?",
    "delete_description": "Sind Sie sicher, dass Sie diese Einsparung dauerhaft löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden, und die gesamte Einsparung wird von der aedifion Cloud-Plattform gelöscht.",
    "keep_btn_text": "Behalten",
    "delete_btn_text": "Löschen"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "dialog_heading": "Delete this saving?",
    "delete_description": "Are you sure you want to permanently delete the entire saving? This action cannot be undone, and the entire saving will be deleted from the aedifion cloud platform.",
    "keep_btn_text": "Keep",
    "delete_btn_text": "Delete"
  }
</i18n>
